import React from 'react'
import styled from 'styled-components'
import { FlexDiv } from '../design-system/FlexDiv'

const Pages = styled.h1`
  font-family: CentraNo2-Book;
  font-size: 45px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  // line-height: 2.44;
  letter-spacing: -0.68px;
  text-align: left;
  color: #ffffff;
  margin-right: 16px;
`

const Rectangle = styled.div`
  width: 27.5em;
  height: 25em;
  opacity: 0.9;
  background-color: #1c1c1c;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 150px;
  display: flex;
  flex-direction: column;
  padding: 0 35px;
`

const Info = styled.div`
  margin: 0px;
  color: white;
  display: flex;
  // background: red;
  justify-content: space-between;
`

const NoPages = styled.h1`
  font-family: CentraNo2-thin;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  // line-height: 4.58;
  letter-spacing: -0.36px;
  text-align: left;
  color: #adadad;
`

const Arrows = styled.div`
  margin-top: 10%;
  // margin-left: 50px;
`

const OurHistoryWrapper = styled.div`
  max-width: 90%;
  margin: auto;
  padding: 2rem 5rem;
  display: inline-flex;
`

const OurHistoryTitle = styled.h3`
  font-family: CentraNo2-Bold;
`

const OurHistoryText = styled.p`
  font-family: CentraNo2-Book;
  font-size: 0.9em;
  line-height: 1.5;
`

export const TermsPage: React.FunctionComponent = () => {
  return (
    <OurHistoryWrapper className="ourHistoryWrapper termsPage">
      <FlexDiv style={{ flexFlow: 'column' }}>
        <FlexDiv className="informationImgWrapper">
          <FlexDiv
            style={{
              flex: 2,
              flexDirection: 'column',
              placeSelf: 'flex-start',
            }}
          >
            <OurHistoryTitle style={{ marginTop: 0 }}>
              Privacy Policy
            </OurHistoryTitle>
            <FlexDiv>
              <OurHistoryText>
                This privacy policy has been compiled to better serve those who are concerned with how their 'Personally identifiable information' (PII) is being used online. We understand the power that the Internet holds for changing your life and making things easier for you. These benefits are at risk if people are concerned about their personal privacy. We are committed to providing you with an Internet experience that respects and protects your personal privacy choices and concerns. In general, we gather information about all of our users collectively. We only use such information anonymously and in the aggregate. This information helps us determine what is most beneficial for our users, and how we can continually create a better overall experience for you. Please read our privacy policy carefully to get a clear understanding of how we collect, use, protect or otherwise handle your Personally Identifiable Information in accordance with our website.
              </OurHistoryText>
            </FlexDiv>
            <h4><strong>Personal Information</strong></h4>
            <OurHistoryText style={{ flex: 1 }}>
              This website functionality requires/requests users to provide contact information (such as their email address) and personal information (such as their names, address phone numbers, and property details). The user’s contact and personal information is used to contact said user when necessary and requested, but is primarily used to collect personal information necessary to effectively market and to sell the property of sellers, to locate, assess and qualify properties for buyers and to otherwise provide professional services to clients and customers.
            </OurHistoryText>
            <h4><strong>Information Collection and Use</strong></h4>
            <OurHistoryText style={{ flex: 1 }}>
              When subscribing to a newsletter or filling out a form, as appropriate, we collect the information you enter, including but not limited to, your name, email address, mailing address, phone number or other details to help you with your experience.
            </OurHistoryText>
            <h4><strong>We may use the information we collect from you in the following ways:</strong></h4>
            <ul>
              <li>To personalize user's experience and to allow us to deliver the type of content and product offerings in which you are most interested.</li>
              <li>To allow us to better service you in responding to your customer service requests</li>
              <li>To administer a contest, promotion, survey or other website feature</li>
              <li>To send periodic emails regarding your interest for property listings and services</li>
            </ul>
            <OurHistoryText style={{ flex: 1 }}>
              This website is the sole owner of the information collected. We will not sell, share, trade or rent this information to others in ways different from what is disclosed in this statement. This website collects information from our users at several different points on our website. We ONLY collect personal information necessary to effectively market and to sell the property of sellers, to locate, assess and qualify properties for buyers and to otherwise provide professional services to clients and customers. We do not sell, trade, transfer, rent or exchange your personal information with anyone. We do not disclose information about your individual visits to this website, or personal information that you provide, such as your name, address, e-mail address, telephone number, etc., to any outside parties, except when we believe the law requires it.
            </OurHistoryText>
            <h4><strong>Home Worth / Dream Home / Neighbourhood Buzzer / Free Real Estate Reports</strong></h4>
            <OurHistoryText style={{ flex: 1 }}>
              Since this website is a Real Estate website, we give you the OPTION of requesting FREE Real Estate Information about real estate properties. Your personal Information is stored on our secure database. We ONLY collect personal information necessary to effectively market and to sell the property of sellers, to locate, assess and qualify properties for buyers and to otherwise provide professional services to clients and customers. We do not sell, trade, transfer, rent or exchange your personal information with anyone.
            </OurHistoryText>
            <h4><strong>Email links</strong></h4>
            <OurHistoryText style={{ flex: 1 }}>
              This website provides an email address link located on the ‘Contact Us’ page so that you may email us directly with any questions or comments you may have. This website reads all messages received and makes efforts to respond promptly. In addition to replying to your comment or inquiry, we may also file your email for future reference regarding improvements to our website or discard the information. Your personal information is not shared, traded, sold, or exchanged with any third parties without your express permission.
            </OurHistoryText>
            

            <h4><strong>This website uses the following cookies:</strong></h4>
            <OurHistoryText style={{ flex: 1 }}>
              This cookie allows us to see information on user website activities including, but not limited to page views, source and time spent on websites. The information is depersonalised and is displayed as numbers, meaning it cannot be tracked back to individuals. This will help to protect your privacy. Using Google Analytics we can see what content is popular on our website, and strive to give you more of the things you enjoy reading and watching.
            </OurHistoryText>
<h4><strong>2. Google AdWords</strong></h4>
<OurHistoryText style={{ flex: 1 }}>
              Using Google AdWords code, we are able to see which pages helped lead to contact form submissions. This allows us to make better use of our paid search budget.
            </OurHistoryText>
            <OurHistoryText style={{ flex: 1 }}>
You can choose to have your computer warn you each time a cookie is being sent, or you can choose to turn off all cookies. You do this through your browser (like Internet Explorer, Google Chrome, Safari, Firefox and other browsers) settings. Each browser is a little different, so look at your browser's Help menu to learn the correct way to modify your cookies.
            </OurHistoryText>
            <OurHistoryText style={{ flex: 1 }}>
              If you disable cookies, some features will be disabled, however, it won't affect the user experience that makes your website experience more efficient. Some of our services may or may not function properly as a result of disabling cookies.
            </OurHistoryText>
            <OurHistoryText style={{ flex: 1 }}>
If you disable cookies, some features will be disabled, however, it won't affect the user experience that makes your website experience more efficient. Some of our services may or may not function properly as a result of disabling cookies.
            </OurHistoryText>
            <OurHistoryText style={{ flex: 1 }}>
Google's advertising requirements can be summed up by Google's Advertising Principles. They are put in place to provide a positive experience for users. You can find more information here: https://support.google.com/adwordspolicy/answer/1316548?hl=en
            </OurHistoryText>
            <OurHistoryText style={{ flex: 1 }}>
However, non-personally identifiable visitor information may be provided to other parties for marketing, advertising, or other uses.
            </OurHistoryText>
<h4><strong>Third Party Disclosure</strong></h4>
            <OurHistoryText style={{ flex: 1 }}>
              We do not sell, trade, or otherwise transfer to outside parties, your personally identifiable information unless we provide you with advance notice. This does not include website hosting partners and other parties who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential. We may also release your information when we believe release is appropriate to comply with the law, enforce our website policies, or protect ours or others' rights, property, or safety.
            </OurHistoryText>
            <h4><strong>Canada Anti-Spam Law (CASL)</strong></h4>
            <OurHistoryText style={{ flex: 1 }}>
              CASL is a law that sets the rules for commercial email, establishes requirements for commercial messages, gives recipients the right to have emails stopped from being sent to them, and spells out tough penalties for violations.
            </OurHistoryText>
<h4><strong>We collect your email address in order to:</strong></h4>
            <ul>
              <li>Send information, respond to inquiries, and/or other requests or questions.</li>
              <li>Market to our mailing list or continue to send emails to our clients after the original transaction has occurred To be in accordance with CASL we agree to the following:</li>
              <li>NOT use false, or misleading subjects or email addresses</li>
              <li>Identify the message as an advertisement in some reasonable way</li>
              <li>Include the physical address of our business or website headquarters</li>
              <li>Monitor third party email marketing services for compliance, if one is used.</li>
              <li>Honor opt-out/unsubscribe requests quickly</li>
              <li>Allow users to unsubscribe by using the link at the bottom of each email</li>
            </ul>
            <h4><strong>Opt-Out</strong></h4>
<OurHistoryText style={{ flex: 1 }}>
            This website provides users the opportunity to opt-out from our mailing list from their accounts. To do this, click on our ‘Unsubscribe’ link found on the following pages: Neighborhood Buzzer/Home Worth/Dream Home/FREE Real Estate Reports. You will be automatically removed from our subscription list upon unsubscribing.
</OurHistoryText>
            <h4><strong>Notification of Changes</strong></h4>
            <OurHistoryText style={{ flex: 1 }}>
This policy may be revised over time as new features are added to the website. We will post those changes so that you will always know what information we gather, how we might use that information, and whether we will disclose it to anyone. Please check this website for information about revisions to our privacy policy. We will notify you directly if there is a material change in our privacy practices. We will take commercially reasonable measures to obtain written or active e-mail consent from the user, if this website is going to be using the information collected from the user in a manner different from that stated at the time of collection. We will also post the changes in our privacy statement 10 days prior to a change.
            </OurHistoryText>
<h4><strong>Legal Disclaimer</strong></h4>
            <OurHistoryText style={{ flex: 1 }}>
              We may disclose personal information when required by law or in the good-faith belief that such action is necessary in order to conform to the edicts of the law or comply with a legal process serviced on our website.
</OurHistoryText>
<h4><strong>Contacting Us</strong></h4>
<OurHistoryText style={{ flex: 1 }}>
            If you have any questions regarding our privacy policy please send us an Email(Click Here) and we will be pleased to assist.
</OurHistoryText>






















          </FlexDiv>
        </FlexDiv>
      </FlexDiv>
    </OurHistoryWrapper>
  )
}
