import React from 'react'
import { EllicotLayout } from 'components/EllicotLayout'
import { TermsPage } from 'components/PrivacyPolicyPage'

const TermsServicePage: React.FunctionComponent = () => {
  return (
    <EllicotLayout>
      <TermsPage />
    </EllicotLayout>
  )
}

export default TermsServicePage
